export type PhotoMenuIntent = 'default' | 'destructive';

export interface PhotoMenuItem {
  title: string;
  value: PhotoOperation;
  icon: JSX.Element;
  intent: PhotoMenuIntent;
  color?: string;
}

export enum PhotoOperation {
  delete = 'Delete',
  update = 'Update'
}

export interface PhotoMenuProps {
  children?: JSX.Element;
  photoExists: boolean;
  handleUpdatePhoto?: () => void;
  handleDeletePhoto?: () => void;
}

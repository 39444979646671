import { useDisclosure } from '@withjoy/joykit';
import { useOuterClick } from '@shared/utils/hooks/useOuterClick';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

import { PhotoMenuProps, PhotoOperation } from './PhotoMenu.types';

export const usePhotoMenuController = ({ handleUpdatePhoto, handleDeletePhoto }: PhotoMenuProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const menuRef = useOuterClick(onToggle, isOpen);

  const handlePhotoMenuClick = (value: PhotoOperation): void => {
    switch (value) {
      case PhotoOperation.delete: {
        handleDeletePhoto && handleDeletePhoto();
        break;
      }
      case PhotoOperation.update: {
        handleUpdatePhoto && handleUpdatePhoto();
        break;
      }
    }
  };

  return {
    handlePhotoMenuClick,
    menuRef,
    isMobile,
    isOpen,
    onToggle
  };
};

import React from 'react';
import { AddCircle, Trash } from '@withjoy/joykit/icons';
import { PhotoMenuItem, PhotoOperation } from './PhotoMenu.types';

export const MENUS: PhotoMenuItem[] = [
  {
    title: 'Update Photo',
    value: PhotoOperation.update,
    icon: <AddCircle />,
    intent: 'default'
  },
  {
    title: 'Delete Photo',
    value: PhotoOperation.delete,
    icon: <Trash />,
    intent: 'destructive'
  }
];

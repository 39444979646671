import { GetCatalogProductDetailsQuery, useGetCatalogProductDetailsLazyQuery as useBaseLazyQuery, useGetCatalogProductDetailsQuery as useBaseQuery } from '@graphql/generated';
import { useEffect, useState } from 'react';

export type UseGetCatalogProductDetailsQueryArgs = {
  catalogItemId: Maybe<string>;
  options?: Parameters<typeof useBaseQuery>[0];
};

export const useGetPdpDataByProductIdQuery = (args: UseGetCatalogProductDetailsQueryArgs) => {
  const { catalogItemId } = args;
  const { variables, onCompleted, ...restOptions } = args.options || {};
  const [productRef, setProductRef] = useState<GetCatalogProductDetailsQuery>();
  const { data, error, loading } = useBaseQuery({
    batchMode: 'fast',
    errorPolicy: 'ignore',
    // To avoid apollo cache entity conflicts, don't store the result of this query.
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
    variables: {
      // Safe to assert existence because we're also using `skip` property
      id: catalogItemId!,
      withPdpSections: true,
      withProductRating: false,
      ...variables
    },
    skip: !catalogItemId,
    onCompleted: data => {
      onCompleted?.(data);
      setProductRef(data);
    },
    ...restOptions
  });

  return {
    data,
    error,
    loading,
    product: __SERVER__ ? data?.productCatalogItemById : productRef?.productCatalogItemById
  };
};

export type UseGetCatalogProductDetailsLazyQueryArgs = {
  catalogItemId: Maybe<string>;
  options?: Parameters<typeof useBaseQuery>[0];
};

export const useGetPdpDataByProductIdLazyQuery = (args: UseGetCatalogProductDetailsLazyQueryArgs) => {
  const { catalogItemId } = args;
  const { variables, onCompleted, ...restOptions } = args.options || {};
  const [productRef, setProductRef] = useState<GetCatalogProductDetailsQuery>();
  const [sendRequest, { data, error, loading }] = useBaseLazyQuery({
    batchMode: 'fast',
    // To avoid apollo cache entity conflicts, don't store the result of this query.
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
    variables: {
      // Safe to assert existence because we're also using `skip` property
      id: catalogItemId!,
      withPdpSections: true,
      withProductRating: false,
      ...variables
    },
    skip: !catalogItemId,
    onCompleted: data => {
      onCompleted?.(data);
      setProductRef(data);
    },
    ...restOptions
  });

  useEffect(() => {
    if (data?.productCatalogItemById) {
      setProductRef(data);
    }
  }, [data]);

  return {
    sendRequest,
    data,
    error,
    loading,
    product: __SERVER__ ? data?.productCatalogItemById : productRef?.productCatalogItemById
  };
};

import React, { useEffect } from 'react';
import { Envelope } from './Envelope';
import { Box } from '@withjoy/joykit';
import { useWindowSize } from './hooks';

export enum IRevealType {
  // Presents the card in an animated envelope opening
  Envelope = 'envelope',
  // Presents the card with no reveal animation
  Default = 'default'
}

export interface RevealRendererProps {
  // The color of the reveal base theme color in hex ( '#3366ff' ) or rgb ( 'rgb(123,45,67)' ) format
  themeColor: string;
  loading?: boolean;
  width?: number;
  verticalOffset?: number;
  autoPlayDelay?: number;
  backgroundColor?: string;
  onThemeTransitionComplete?: () => void;
  blockInteraction?: boolean;
}

interface RevealProps extends RevealRendererProps {
  // The reveal animation type
  type: IRevealType;
}

const DefaultReveal: React.FC<RevealRendererProps> = ({ children, onThemeTransitionComplete, themeColor, backgroundColor }) => {
  const [curThemeColor, setCurThemeColor] = React.useState(themeColor);
  useEffect(() => {
    if (curThemeColor !== themeColor) {
      setCurThemeColor(themeColor);
      onThemeTransitionComplete?.();
    }
  }, [themeColor, onThemeTransitionComplete, curThemeColor]);
  return (
    <Box padding={6} backgroundColor={backgroundColor}>
      {children}
    </Box>
  );
};

const typeComponentMap = {
  [IRevealType.Envelope]: Envelope,
  [IRevealType.Default]: DefaultReveal
};

const DEFAULT_CONTENT_WIDTH = 460;
const DEFAULT_PADDING = 40;

export const Reveal: React.FC<RevealProps> = ({
  themeColor,
  type,
  width,
  verticalOffset,
  autoPlayDelay,
  backgroundColor,
  onThemeTransitionComplete,
  loading,
  blockInteraction,
  children
}) => {
  const RevealRenderer = typeComponentMap[type] || typeComponentMap[IRevealType.Default];
  const { width: winWidth, height: winHeight } = useWindowSize();
  let renderWidth = width;
  if (!width) {
    renderWidth = winWidth > DEFAULT_CONTENT_WIDTH + DEFAULT_PADDING ? DEFAULT_CONTENT_WIDTH : winWidth - DEFAULT_PADDING;
  }
  const renderHeight = typeof verticalOffset === 'number' ? verticalOffset : winHeight;

  return (
    <RevealRenderer
      themeColor={themeColor}
      backgroundColor={backgroundColor}
      width={renderWidth}
      verticalOffset={renderHeight}
      autoPlayDelay={autoPlayDelay}
      onThemeTransitionComplete={onThemeTransitionComplete}
      loading={loading}
      blockInteraction={blockInteraction}
    >
      {children}
    </RevealRenderer>
  );
};

/**
 * `totalRegistryItemsCount` mimics the logic from `calculateRegistryAdvisorCounts.currentGiftCount` in
 * https://github.com/joylifeinc/recommend_service/blob/main/src/utils/registryAdvisorCounts/registryAdvisorCalculator.ts
 * `.reduce((acc, gift) => (gift.isGroupGiftingEnabled ? acc + 1 : acc + (gift.totalRequested ?? 0)), 0)`
 * The ammount of gifts used mimics `validEventRegistryItems` in
 * https://github.com/joylifeinc/recommend_service/blob/main/src/models/recommend/controller/recommendRegistryAdvisorController.ts
 * `items.filter(item => !item.isHidden && item.productData.price?.valueInMinorUnits && item.totalRequested)`
 *
 * This data comes from the registry recommendation service in `eventByName.recommendation.registryAdvisor.currentGiftCount`,
 * but when there is no `guestCount` this data is not returned. This will happen on events that haven't completed their
 * guest count either in the survey or in the registry onboarding survey. It will also happen on non-wedding events, that
 * currently don't have a guest count.
 *
 * @param items - An array of registry items.
 * @returns The total count of registry items that meet the conditions.
 */

type Item = {
  isHidden: boolean;
  productData: {
    price?: Maybe<{
      valueInMinorUnits: number;
    }>;
  };
  totalRequested?: Maybe<number>;
  isGroupGiftingEnabled?: Maybe<boolean>;
};

export const getTotalRegistryItemsCount = (items: Maybe<Item[]>) => {
  if (!items) {
    return 0;
  }

  return items
    .filter(item => !item.isHidden && item.productData.price?.valueInMinorUnits && item.totalRequested)
    .reduce((acc, gift) => (gift.isGroupGiftingEnabled ? acc + 1 : acc + (gift.totalRequested ?? 0)), 0);
};

import React, { useMemo } from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { Flex, DrawerV2, TextV2 } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

import { MENUS } from './PhotoMenu.constants';
import { usePhotoMenuController } from './PhotoMenu.controller';
import { PhotoMenuItem, PhotoMenuProps, PhotoOperation } from './PhotoMenu.types';
import { PhotoMenuRoot, drawerOverrides, ItemList, optionButtonStyles } from './PhotoMenu.styles';

export const PhotoMenu: React.FC<PhotoMenuProps> = ({ photoExists, handleUpdatePhoto, handleDeletePhoto, children }) => {
  const { menuRef, isMobile, isOpen, onToggle, handlePhotoMenuClick } = usePhotoMenuController({
    photoExists,
    handleUpdatePhoto,
    handleDeletePhoto
  });

  const content = useMemo(
    () =>
      MENUS?.filter(item => item.value !== PhotoOperation.delete || (item.value === PhotoOperation.delete && photoExists)).map((item: PhotoMenuItem) => (
        <Flex
          key={item.title}
          as="div"
          columnGap={3}
          textAlign="left"
          borderRadius={2}
          paddingY={4}
          paddingX={3}
          alignItems="center"
          onClick={() => handlePhotoMenuClick(item.value)}
          width={['100%', '100%', pxToRem(200)]}
          __css={optionButtonStyles(item.intent)}
        >
          {item.icon}
          <TextV2 typographyVariant="body1">{item.title}</TextV2>
        </Flex>
      )),
    [handlePhotoMenuClick, photoExists]
  );

  return (
    <>
      <PhotoMenuRoot as={'div'} onClick={onToggle} isOpen={isOpen}>
        {children}
        {!isMobile && (
          <ItemList
            ref={menuRef}
            isOpen={isOpen}
            flexDirection="column"
            rowGap={3}
            padding={3}
            borderRadius={3}
            visibility={isOpen ? 'visible' : 'hidden'}
            position="absolute"
            opacity={isOpen ? 1 : 0}
            left={0}
            backgroundColor="white"
            boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06)"
            transform={isOpen ? 'scaleY(1)' : 'scaleY(0.8)'}
            transformOrigin="left top"
            transition={animationTransition('opacity', 'transform', 'box-shadow', 'height')}
            transitionDuration="0.3s"
          >
            {content}
          </ItemList>
        )}
      </PhotoMenuRoot>
      {isMobile && (
        <DrawerV2 useBackdrop={false} overrides={drawerOverrides} isOpen={isOpen} anchor="bottom">
          <Flex flexDirection="column" rowGap={3} ref={menuRef}>
            {content}
          </Flex>
        </DrawerV2>
      )}
    </>
  );
};

/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Help',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M12 7.24981C10.6745 7.24981 9.6 8.32433 9.6 9.64981C9.6 9.98118 9.33137 10.2498 9 10.2498C8.66863 10.2498 8.4 9.98118 8.4 9.64981C8.4 7.66159 10.0118 6.04981 12 6.04981C13.9882 6.04981 15.6 7.66159 15.6 9.64981C15.6 11.0092 14.6982 11.8692 13.9974 12.5375L13.9148 12.6164C13.143 13.3549 12.6 13.9245 12.6 14.8225C12.6 15.1539 12.3314 15.4225 12 15.4225C11.6686 15.4225 11.4 15.1539 11.4 14.8225C11.4 13.3615 12.3386 12.4636 13.064 11.7696L13.0852 11.7494C13.875 10.9937 14.4 10.4676 14.4 9.64981C14.4 8.32433 13.3255 7.24981 12 7.24981Z"
            fill="currentColor"
          />
          <path
            d="M12 17.9127C12.3452 17.9127 12.625 17.6329 12.625 17.2877C12.625 16.9425 12.3452 16.6627 12 16.6627C11.6548 16.6627 11.375 16.9425 11.375 17.2877C11.375 17.6329 11.6548 17.9127 12 17.9127Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 5.93286 5.93286 1 12 1C18.0671 1 23 5.93286 23 12C23 18.0671 18.0671 23 12 23C5.93286 23 1 18.0671 1 12ZM12 2.2C6.5956 2.2 2.2 6.5956 2.2 12C2.2 17.4044 6.5956 21.8 12 21.8C17.4044 21.8 21.8 17.4044 21.8 12C21.8 6.5956 17.4044 2.2 12 2.2Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;

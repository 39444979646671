import React, { useMemo } from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { DateText, StyledTextBox, CoupleWrapper, NoteAndPhotoRow, DashboardButtonWrapper, styles, StyledRoot, StyledTitle } from './CoupleHeader.styles';
import { useCoupleHeaderController } from './CoupleHeader.controller';
import { PageNoteText } from './PageNoteText';
import { DashboardButton, NavBar } from '@apps/registry/guest/components';
import { DesignLayoutType, EventPageFragment, EventPageType, EventType } from '@graphql/generated';
import { brannanConfig } from '@apps/guest/packages/layout-engine/layouts/LayoutBrannan';
import { UserProfile } from '@shared/components/AuthProvider';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useEventType } from '@shared/utils/eventType';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { EventPhoto } from '@apps/registry/common/components/EventPreview/components/EventPhoto/EventPhoto';

const { componentTitleBanner } = brannanConfig.applicatorHtmlProps;

interface BrannanHeaderProps {
  website: string;
  font: string;
  emptyNote: boolean;
  layout?: DesignLayoutType;
  primaryTextColor?: string;
  pages?: ReadonlyArray<EventPageFragment>;
  currentUser: UserProfile;
  onHandleLogout: () => void;
  isAdmin: boolean;
  isNavBarVisible: boolean;
}

interface Props
  extends Readonly<{
    website: string;
    eventDate?: string | null;
    pageNote?: string | null;
    isAdmin: boolean;
    registryPhoto?: string;
    eventId: string;
    firebaseId?: string;
    font?: string;
    pages?: ReadonlyArray<EventPageFragment>;
    layout?: DesignLayoutType;
    primaryTextColor?: string;
    isNative: boolean;
  }> {}

const BrannanHeader = ({ website, font, emptyNote, layout, pages, currentUser, onHandleLogout, primaryTextColor, isAdmin, isNavBarVisible }: BrannanHeaderProps) => {
  const { eventType } = useEventType();
  const { getCoupleHeaderTranslations } = useRegistryGuestTranslations();
  const { eventInfo } = useEventInfo();
  const translations = getCoupleHeaderTranslations().defaultName;
  const defaultDisplayName = translations[eventType ?? EventType.general]();

  return (
    <>
      <StyledRoot position={'relative'} minHeight={[291, 200, null, 'initial']} {...componentTitleBanner.prop}>
        <StyledTitle __css={styles.title}>
          <TextV2 typographyVariant={'display6'} fontFamily={font} fontWeight={400} marginBottom={[7, null, emptyNote ? 9 : 56]}>
            {eventInfo?.eventDisplayName ?? defaultDisplayName}
          </TextV2>
        </StyledTitle>
      </StyledRoot>
      <Flex justifyContent={'space-between'} alignItems={'center'} paddingX={['10px', null, '0px']} width={'100%'}>
        {isNavBarVisible && (
          <NavBar layout={layout} pages={pages} font={font} eventHandle={website} currentUser={currentUser} onHandleLogout={onHandleLogout} primaryTextColor={primaryTextColor} />
        )}
      </Flex>

      <Flex margin="0 auto">
        {/* Manage Registry Button */}
        {isAdmin && (
          <DashboardButtonWrapper __css={styles.dashboardButtonWrapperBrannan}>
            <DashboardButton website={website} />
          </DashboardButtonWrapper>
        )}
      </Flex>
    </>
  );
};

export const CoupleHeader: React.FC<Props> = ({
  eventDate,
  pageNote,
  isAdmin,
  registryPhoto,
  website,
  firebaseId,
  eventId,
  font = 'Austin News Headline',
  pages,
  layout,
  primaryTextColor,
  isNative
}) => {
  const { handleOnUpdatePhoto, handleDeletePhoto, onEditPageNote, onContinueReading, currentUser, onHandleLogout, isNavBarVisible, photo } = useCoupleHeaderController({
    eventId,
    firebaseId,
    isAdmin,
    website,
    registryPhoto,
    pages
  });

  const registryPage = useMemo(() => pages?.find(page => page.type === EventPageType.registry), [pages]);
  const eventPageId = registryPage?.id ?? '';

  const photoExists = !!photo;
  const emptyNote = !pageNote?.length;
  return (
    <Box>
      {!isNative && (
        <BrannanHeader
          layout={layout}
          pages={pages}
          font={font}
          currentUser={currentUser}
          onHandleLogout={onHandleLogout}
          primaryTextColor={primaryTextColor}
          emptyNote={emptyNote}
          isAdmin={isAdmin}
          website={website}
          isNavBarVisible={isNavBarVisible}
        />
      )}
      <CoupleWrapper margin={{ md: '0 auto' }}>
        <NoteAndPhotoRow>
          <StyledTextBox fullWidth={!isAdmin && !photoExists}>
            <Box>
              {eventDate && !isNative && (
                <Box width="100%" marginBottom={emptyNote ? 6 : 5}>
                  <DateText typographyVariant={{ _: 'hed3', md: 'hed5' }}>{eventDate}</DateText>
                </Box>
              )}
              <Box>
                <PageNoteText eventHandle={website} noteText={pageNote} onClickReadMore={onContinueReading} showEdit={isAdmin} onEditPageNote={onEditPageNote} />
              </Box>
            </Box>
          </StyledTextBox>
          {(isAdmin || photoExists) && (
            <EventPhoto
              photo={photo}
              firebaseId={firebaseId}
              eventPageId={eventPageId}
              showEdit={isAdmin && !isNative}
              onDeletePhoto={handleDeletePhoto}
              onUpdatePhoto={handleOnUpdatePhoto}
            />
          )}
        </NoteAndPhotoRow>
      </CoupleWrapper>
    </Box>
  );
};

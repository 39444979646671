export const addItem = <T>(source: Array<T>, item: T) => {
  return [...source, item];
};

export const removeItem = <T>(source: Array<T>, target: T) => {
  return source.filter(item => item !== target);
};

export const hasIntersection = (arr1: string[], arr2: string[]): boolean => {
  const arr1Set = new Set(arr1);
  return arr2.some(val => arr1Set.has(val));
};
